import React, { useEffect, useRef } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"
import { Image } from "../../components/gatsby-images/image"
import { connect } from "react-redux"

const RoomPhotosComponent = ({
  mainImage,
  portraitPhoto,
  smallImage,
  scrolltriggerReady,
}) => {
  let portraitImg = useRef(null)
  let smallImg = useRef(null)
  let mainImg = useRef(null)

  //tween
  let portraitTween = useRef(null)
  let smallImgTween = useRef(null)

  useEffect(() => {
    if (scrolltriggerReady) {
      ScrollTrigger.getById(`portrait-img-room-${mainImage}`) &&
        ScrollTrigger.getById(`portrait-img-room-${mainImage}`).kill()
      portraitTween.current = gsap.fromTo(
        portraitImg,
        { x: -100 },
        {
          scrollTrigger: {
            id: `portrait-img-room-${mainImage}`,
            trigger: mainImg,
            start: "bottom 85%",
            end: "+=200",
            scrub: true,
          },
          x: 0,
        }
      )
      ScrollTrigger.getById(`portrait-img-room-${smallImage}`) &&
        ScrollTrigger.getById(`portrait-img-room-${smallImage}`).kill()
      smallImgTween.current = gsap.fromTo(
        smallImg,
        { x: 100 },
        {
          scrollTrigger: {
            id: `portrait-img-room-${smallImage}`,
            trigger: mainImg,
            start: "bottom 85%",
            end: "+=200",
            scrub: true,
          },
          x: 0,
        }
      )
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    smallImgTween.current && smallImgTween.current.kill()
    portraitTween.current && portraitTween.current.kill()
  }, [])

  return (
    <div className="rooms-photos">
      <div className="row">
        <div className="col-10 offset-1">
          <div className="main-image-room" ref={el => (mainImg = el)}>
            <Image src={mainImage} />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-5 offset-1 portrait-image"
          ref={el => (portraitImg = el)}
        >
          <Image src={portraitPhoto} />
        </div>
        <div className="col-5 small-image" ref={el => (smallImg = el)}>
          <Image src={smallImage} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(RoomPhotosComponent)
