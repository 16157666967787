import React from "react"
import { Image } from "../components/gatsby-images/image"
import RoomComponent from "./roomcomponent/RoomComponent"

const Product = ({ pageContext }) => {
  const room = pageContext

  return (
    <>
      <RoomComponent roomObj={room} />
    </>
  )
}

export default Product
