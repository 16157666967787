import { Trans } from "@lingui/macro"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Image } from "../../components/gatsby-images/image"
import TransitionLink from "gatsby-plugin-transition-link"

const roomsList = {
  en: [
    {
      name: "Executive",
      url: "executive-room",
      img: "main-image-room-photos-8",
      view: "castle",
    },
    {
      name: "Comfort",
      url: "confort-room",
      img: "main-image-room-6",
      view: "city",
    },
    {
      name: "Comfort Superior",
      url: "confort-superior-room",
      img: "comfort-superior-list-rooms",
      view: "city",
    },
    {
      name: "Privilege",
      url: "privilege-room",
      img: "privilege-list-rooms",
      view: "city",
    },
    {
      name: "Prestige Bleue",
      url: "prestige-canard-room",
      img: "main-image-room-5",
      view: "castle",
    },
    {
      name: "Prestige Anis",
      url: "prestige-anis-room",
      img: "main-image-room-anis",
      view: "castle",
    },
    {
      name: "Prestige Mauve",
      url: "prestige-mauve-room",
      img: "main-image-room-mauve",
      view: "castle",
    },
    {
      name: "Prestige Terrace",
      url: "prestige-terrasse-room",
      img: "prestige-terrasse-list-rooms",
      view: "terrace",
    },
    {
      name: "Garden Comfort",
      url: "garden-comfort-room",
      img: "garden-comfort-list-rooms",
      view: "terrace",
    },
    {
      name: "Garden Prestige ",
      url: "garden-prestige-room",
      img: "garden-prestige-list-rooms",
      view: "terrace",
    },
  ],
  fr: [
    {
      name: "Exécutive",
      url: "executive-room",
      img: "main-image-room-photos-8",
      view: "castle",
    },
    {
      name: "Confort",
      url: "confort-room",
      img: "main-image-room-6",
      view: "city",
    },
    {
      name: "Confort Supérieur",
      url: "confort-superior-room",
      img: "comfort-superior-list-rooms",
      view: "city",
    },
    {
      name: "Privilége",
      url: "privilege-room",
      img: "privilege-list-rooms",
      view: "city",
    },
    {
      name: "Prestige Bleue",
      url: "prestige-canard-room",
      img: "main-image-room-5",
      view: "castle",
    },
    {
      name: "Prestige Anis",
      url: "prestige-anis-room",
      img: "main-image-room-anis",
      view: "castle",
    },
    {
      name: "Prestige Mauve",
      url: "prestige-mauve-room",
      img: "main-image-room-mauve",
      view: "castle",
    },
    {
      name: "Prestige Terrace",
      url: "prestige-terrasse-room",
      img: "prestige-terrasse-list-rooms",
      view: "terrace",
    },
    {
      name: "Confort Garden",
      url: "garden-comfort-room",
      img: "garden-comfort-list-rooms",
      view: "terrace",
    },
    {
      name: "Prestige Garden",
      url: "garden-prestige-room",
      img: "garden-prestige-list-rooms",
      view: "terrace",
    },
  ],
  ru: [
    {
      name: "Экзекьютив",
      url: "executive-room",
      img: "main-image-room-photos-8",
      view: "castle",
    },
    {
      name: "Конфор",
      url: "confort-room",
      img: "main-image-room-6",
      view: "city",
    },
    {
      name: "Конфор Сюперьор",
      url: "confort-superior-room",
      img: "comfort-superior-list-rooms",
      view: "city",
    },
    {
      name: "Привилеж",
      url: "privilege-room",
      img: "privilege-list-rooms",
      view: "city",
    },
    {
      name: "Престиж Блё Канар",
      url: "prestige-canard-room",
      img: "main-image-room-5",
      view: "castle",
    },
    {
      name: "Престиж Рум Анис",
      url: "prestige-anis-room",
      img: "main-image-room-anis",
      view: "castle",
    },
    {
      name: "Престиж Рум Мов",
      url: "prestige-mauve-room",
      img: "main-image-room-mauve",
      view: "castle",
    },
    {
      name: "Престиж Террас",
      url: "prestige-terrasse-room",
      img: "prestige-terrasse-list-rooms",
      view: "terrace",
    },
    {
      name: "Конфор Жарден",
      url: "garden-comfort-room",
      img: "garden-comfort-list-rooms",
      view: "terrace",
    },
    {
      name: "Престиж Жарден",
      url: "garden-prestige-room",
      img: "garden-prestige-list-rooms",
      view: "terrace",
    },
  ],
}

const OthersRooms = ({
  roomsAvailabilities,
  language,
  startingDate,
  endingDate,
  resetOffset,
}) => {
  const [othersAvailabilities, setOthersAvailabilities] = useState([])
  const [roomsObj, setRoomsObj] = useState([])
  const [citySideRooms, setCitySideRooms] = useState([])
  const [castleSideRooms, setCastleSideRooms] = useState([])
  const [terraceSideRooms, setTerraceSideRooms] = useState([])

  useEffect(() => {
    setRoomsObj(roomsList[language])
  }, [language])

  useEffect(() => {
    if (roomsObj.length) {
      const url = new URL(window.location.href).pathname
        .split("/")
        .filter(Boolean)
        .pop()
      console.log(url)
      let tempCity = []
      let tempCastle = []
      let tempTerrace = []

      let tempOthersAvail = roomsObj.filter(roomObj => {
        return roomObj["url"] !== url
      })
      tempOthersAvail.forEach(obj => {
        if (obj["view"] == "city") {
          tempCity.push(obj)
        } else if (obj["view"] == "castle") {
          tempCastle.push(obj)
        } else {
          tempTerrace.push(obj)
        }
      })
      setCitySideRooms(tempCity)
      setCastleSideRooms(tempCastle)
      setTerraceSideRooms(tempTerrace)

      setOthersAvailabilities(tempOthersAvail)
    } else {
      setOthersAvailabilities(roomsAvailabilities)
    }
  }, [roomsObj])

  // useEffect(() => {
  //     let tempCity = []
  //     let tempCastle = []
  //     let tempTerrace = []
  //     if(startingDate && endingDate && roomsAvailabilities) {
  //         roomsAvailabilities.map(roomObj =>{
  //             citySideRooms.forEach(obj => roomObj[obj['url']] && tempCity.push(obj))
  //             castleSideRooms.forEach(obj => roomObj[obj['url']] && tempCastle.push(obj))
  //             terraceSideRooms.forEach(obj => roomObj[obj['url']] && tempTerrace.push(obj))
  //             }
  //         )
  //         setCitySideRooms(tempCity)
  //         setCastleSideRooms(tempCastle)
  //         setTerraceSideRooms(tempTerrace)
  //     }
  // }, [roomsObj])

  // useEffect(() => {
  //     let tempCity = []
  //     let tempCastle = []
  //     let tempTerrace = []

  //     roomsAvailabilities.map(roomObj =>{
  //         citySideRooms.forEach(obj => roomObj[obj['url']] && tempCity.push(obj))
  //         castleSideRooms.forEach(obj => roomObj[obj['url']] && tempCastle.push(obj))
  //         terraceSideRooms.forEach(obj => roomObj[obj['url']] && tempTerrace.push(obj))
  //         }
  //     )
  //     setCitySideRooms(tempCity)
  //     setCastleSideRooms(tempCastle)
  //     setTerraceSideRooms(tempTerrace)
  // }, [startingDate, endingDate, roomsAvailabilities])
  return (
    <div className="others-rooms">
      <div className="row">
        <div className="title offset-1">
          {
            //  (citySideRooms.length || castleSideRooms.length || terraceSideRooms.length) ?
            //     <Trans>Nos autres chambres disponibles</Trans>
            //     :
            <Trans>Nos autres chambres</Trans>
          }
        </div>
      </div>
      <div>
        <div className="row">
          {citySideRooms.length && (
            <div className="subtitle offset-2 col-12">
              <Trans>City view:</Trans>
            </div>
          )}
        </div>
        <div className="row rooms-list">
          <div className="offset-1 col-11">
            <div className="flex">
              {citySideRooms.map(roomObj => {
                return (
                  <div className="card text-center">
                    <TransitionLink
                      to={`/${language}/rooms/${roomObj["url"]}`}
                      // exit={{
                      //     delay: 2,
                      // }}
                      entry={{
                        trigger: ({ node, e, exit, entry }) => {
                          // hideTransitionBanner()
                          resetOffset(true)
                        },
                      }}
                    >
                      <div className="title">{roomObj["name"]}</div>
                      <Image src={roomObj["img"]} />
                    </TransitionLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row">
          {castleSideRooms.length && (
            <div className="subtitle offset-2 col-12">
              <Trans>Castle view:</Trans>
            </div>
          )}
        </div>
        <div className="row rooms-list">
          <div className="offset-1 col-11">
            <div className="flex">
              {castleSideRooms.map(roomObj => {
                return (
                  <div className="card text-center">
                    <TransitionLink
                      to={`/${language}/rooms/${roomObj["url"]}`}
                      // exit={{
                      //     delay: 2,
                      // }}
                      entry={{
                        trigger: ({ node, e, exit, entry }) => {
                          // hideTransitionBanner()
                          resetOffset(true)
                        },
                      }}
                    >
                      <div className="title">{roomObj["name"]}</div>
                      <Image src={roomObj["img"]} />
                    </TransitionLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row">
          {terraceSideRooms.length && (
            <div className="subtitle offset-2 col-12">
              <Trans>Private Terrrace:</Trans>
            </div>
          )}
        </div>
        <div className="row rooms-list">
          <div className="offset-1 col-11">
            <div className="flex">
              {terraceSideRooms.map(roomObj => {
                return (
                  <div className="card text-center">
                    <TransitionLink
                      to={`/${language}/rooms/${roomObj["url"]}`}
                      // exit={{
                      //     delay: 2,
                      // }}
                      entry={{
                        trigger: ({ node, e, exit, entry }) => {
                          // hideTransitionBanner()
                          resetOffset(true)
                        },
                      }}
                    >
                      <div className="title">{roomObj["name"]}</div>
                      <Image src={roomObj["img"]} />
                    </TransitionLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    loadingBanner: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

const mapStateToProps = ({
  roomsAvailabilities,
  language,
  startingDate,
  endingDate,
}) => {
  return { roomsAvailabilities, language, startingDate, endingDate }
}

export default connect(mapStateToProps, mapDispatchToProps)(OthersRooms)
