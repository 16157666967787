import React, { useRef, useEffect, useState, useContext } from "react"
// import { Link } from 'gatsby'
import gsap from "gsap/gsap-core"
// import ChambreSvg from './ChambreSvg'
// import { connect } from "react-redux"
import TransitionLink from "gatsby-plugin-transition-link"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"

const RoomChanger = ({
  nextUrl,
  prevUrl,
  nextTitle,
  prevTitle,
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  loadingBanner,
  language,
}) => {
  const [prevButtonHovered, setPrevButtonHovered] = useState(false)
  const [prevBtnWidth, setPrevBtnWidth] = useState(100)
  let prevRoom = useRef(null)
  let prevBtn = useRef(null)
  let prevBtnLine = useRef(null)

  const [nextButtonHovered, setNextButtonHovered] = useState(false)
  const [nextBtnWidth, setNextBtnWidth] = useState(100)
  let nextRoom = useRef(null)
  let nextBtn = useRef(null)
  let nextBtnLine = useRef(null)

  let tl = gsap.timeline()

  const prevButtonHoveredSwitch = el => {
    prevButtonHovered ? setPrevButtonHovered(false) : setPrevButtonHovered(true)
  }
  const nextButtonHoveredSwitch = el => {
    nextButtonHovered ? setNextButtonHovered(false) : setNextButtonHovered(true)
  }
  useEffect(() => {
    gsap.to(prevBtnLine, { xPercent: -100 })
    setPrevBtnWidth(prevBtn.getBoundingClientRect().width)
    if (prevButtonHovered) {
      let tl = gsap.timeline()
      setPrevButtonHovered(true)
      tl.to(prevRoom, { autoAlpha: 0 })
        .to(prevBtn, { color: "rgb(164,13,13)" })
        .to(prevBtnLine, { xPercent: 0 }, "<")
    } else {
      let tl = gsap.timeline()
      setPrevButtonHovered(false)
      tl.to(prevBtn, { color: "black" })
        .to(prevBtnLine, { xPercent: -100 }, "<")
        .to(prevRoom, { autoAlpha: 1 })
    }
  }, [prevButtonHovered])

  useEffect(() => {
    gsap.to(nextBtnLine, { xPercent: -100 })
    setNextBtnWidth(nextBtn.getBoundingClientRect().width)
    if (nextButtonHovered) {
      tl.to(nextRoom, { autoAlpha: 0 })
        .to(nextBtn, { color: "rgb(164,13,13)" })
        .to(nextBtnLine, { xPercent: 0 }, "<")
    } else {
      tl.to(nextBtn, { color: "black" })
        .to(nextBtnLine, { xPercent: -100 }, "<")
        .to(nextRoom, { autoAlpha: 1 })
    }
  }, [nextButtonHovered])

  return (
    <div className="room-changer-container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <div
            className="buttons"
            onClick={() => {
              loadingBanner(true)
              showTransitionBanner()
            }}
          >
            <TransitionLink
              to={`/${language}/rooms/${prevUrl}`}
              exit={{
                delay: 2,
              }}
              entry={{
                trigger: ({ node, e, exit, entry }) => {
                  hideTransitionBanner()
                  resetOffset(true)
                },
              }}
            >
              <div
                className="button text-center"
                onMouseEnter={() => prevButtonHoveredSwitch()}
                onMouseLeave={() => prevButtonHoveredSwitch()}
              >
                <div className="title">{prevTitle}</div>
                <div>
                  <div style={{ position: "relative", height: "30vw" }}>
                    <div className="changer" ref={el => (prevBtn = el)}>
                      <Trans>Précédente</Trans>
                      <div
                        className="underline"
                        ref={el => (prevBtnLine = el)}
                        style={{ width: prevBtnWidth }}
                      ></div>
                    </div>
                    <div className="hand-typo" ref={el => (prevRoom = el)}>
                      <Trans>Chambre changer</Trans>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionLink>
            <TransitionLink
              to={`/${language}/rooms/${nextUrl}`}
              exit={{
                trigger: ({ node, e, exit, entry }) => {},
                delay: 2,
              }}
              entry={{
                trigger: ({ node, e, exit, entry }) => {
                  hideTransitionBanner()
                  resetOffset(true)
                },
              }}
            >
              <div
                className="button text-center"
                onMouseEnter={() => nextButtonHoveredSwitch()}
                onMouseLeave={() => nextButtonHoveredSwitch()}
              >
                <div className="title">{nextTitle}</div>
                <div
                // style={{overflow: 'hidden', width:'45vw', height: '30vw'}}
                >
                  <div style={{ position: "relative", height: "30vw" }}>
                    <div className="changer" ref={el => (nextBtn = el)}>
                      <Trans>Suivante</Trans>

                      <div
                        className="underline"
                        ref={el => (nextBtnLine = el)}
                        style={{ width: nextBtnWidth }}
                      ></div>
                    </div>
                    <div className="hand-typo" ref={el => (nextRoom = el)}>
                      <Trans>Chambre changer</Trans>
                      {/* <ChambreSvg nextButtonHovered={nextButtonHovered}/> */}
                    </div>
                  </div>
                </div>
              </div>
            </TransitionLink>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ language }) => {
  return { language }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    loadingBanner: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomChanger)
