import React, { useEffect, useState, useRef } from "react"
import Banner from "./Banner"
import MirrorComponent from "./MirrorComponent"
import PhotoSlider from "./PhotoSlider"
import RoomPhotosComponent from "./RoomPhotosComponent"
import Bathroom from "./Bathroom"
import LastPhotoSlider from "./LastPhotoSlider"
import RoomChanger from "./RoomChanger"
import { connect } from "react-redux"
import SEO from "../../components/seo"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import OthersRooms from "./OthersRooms"

const RoomComponent = ({
  roomObj,
  updateShowNavbar,
  updateRoomReservation,
  language,
  updateShowRoomReservationBtn,
  roomReservationInfos,
  roomListColor,
  updateRoomsListColor,
  updateRoomUrl,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  let bannerObj = roomObj.roomComponent[language].banner
  let firstSliderObj = roomObj.roomComponent[language].firstSlider
  let mirrorPhotoObj = roomObj.roomComponent[language].mirrorPhotoObj
  let roomPhotosObj = roomObj.roomComponent[language].roomPhotos
  let mainBathroomImg = roomObj.roomComponent[language].mainBathroomImg
  let bathroomObj = roomObj.roomComponent[language].bathroomPhotos
  let lastSliderObj = roomObj.roomComponent[language].lastSlider
  let lastMirrorPhoto = roomObj.roomComponent[language].lastMirrorPhoto
  let changeRoom = roomObj.roomComponent[language].changeRoom
  let roomTitle = roomObj.roomComponent[language].banner.title
  let urlLanguage = "fr"

  if (typeof window !== "undefined") {
    const url = window.location.pathname

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }

  //show arrow down
  let tempScrollTriggerRoomCompRef = useRef(null)
  //state
  const [pageHeight, setPageHeight] = useState("")

  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  useEffect(() => {
    setTimeout(() => updateShowNavbar({ show: false, delay: 0 }), 10)
    if (roomListColor != "white") {
      setTimeout(() => updateRoomsListColor("white"), 100)
      updateRoomsListColor("white")
    }

    //if price not yet defined, take the start, end dates and
    //fetch the availabilities for specific room and date
    if (!roomReservationInfos.price) {
      updateRoomReservation({ price: 200, availability: true })
    }

    updateShowRoomReservationBtn(true)
    let roomUrl = window.location.pathname
      .replace("/rooms/", "")
      .replace(`${language}`, "")
      .replace("/", "")
      .replace("/", "")

    updateRoomUrl(roomUrl)

    const tempScrollTrigger = ScrollTrigger.create({ id: "temp-contact" })
    setPageHeight(tempScrollTrigger["scroller"]["scrollHeight"])
    tempScrollTriggerRoomCompRef.current = tempScrollTrigger

    return () => {
      //TODO UPDATE ROOM CATEGORY TO GET AVAILABILITY ON ROOM PAGE CHANGE

      tempScrollTriggerRoomCompRef.current &&
        tempScrollTriggerRoomCompRef.current.kill()

      if (!window.location.pathname.includes("-room")) {
        updateShowNavbar({ show: true, delay: 2000 })
        updateShowRoomReservationBtn(false)
      }
    }
  }, [])

  return (
    <div className="room-component">
      <SEO
        title={`Hotel Au Charme Rabelaisien **** - ${roomTitle}`}
        description={
          urlLanguage == "fr"
            ? `Bienvenue dans la page de la chambre ${roomTitle}`
            : `Welcome to the page of the ${roomTitle}'s room`
        }
      />
      <Banner roomObj={bannerObj} />
      <PhotoSlider
        sliderImg={firstSliderObj.slider}
        bigImg={firstSliderObj.square}
      />
      <MirrorComponent
        mainImage={mirrorPhotoObj.mainImage}
        mirrorImage={mirrorPhotoObj.mirrorImage}
      />
      <RoomPhotosComponent
        mainImage={roomPhotosObj.mainImage}
        portraitPhoto={roomPhotosObj.portraitPhoto}
        smallImage={roomPhotosObj.smallImage}
      />
      <Bathroom
        mainImage={mainBathroomImg}
        firstImage={bathroomObj.firstImage}
        secondImage={bathroomObj.secondImage}
        thirdImage={bathroomObj.thirdImage}
      />
      <MirrorComponent
        mainImage={lastMirrorPhoto.mainImage}
        mirrorImage={lastMirrorPhoto.mirrorImage}
      />
      <LastPhotoSlider
        sliderImg={lastSliderObj.slider}
        bigImg={lastSliderObj.bigImage}
      />
      <OthersRooms />
      {/* <RoomChanger 
                nextUrl={changeRoom.nextButton.url}
                nextTitle={changeRoom.nextButton.title}
                prevUrl={changeRoom.prevButton.url}
                prevTitle={changeRoom.prevButton.title}
            /> */}
    </div>
  )
}

const mapStateToProps = ({
  roomReservationInfos,
  language,
  roomListColor,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  return {
    roomReservationInfos,
    language,
    roomListColor,
    scrollOffset,
    updateShowArrowDown,
    scrolltriggerReady,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowNavbar: navbarObj =>
      dispatch({
        type: `UPDATE_SHOW_NABAR`,
        payload: {
          show: navbarObj.show,
          delay: navbarObj.delay,
        },
      }),
    updateShowRoomReservationBtn: bool =>
      dispatch({ type: `SHOW_ROOM_RESERVATION`, payload: bool }),
    updateRoomReservation: roomObj =>
      dispatch({ type: `UPDATE_ROOM_RESERVATION_INFO`, payload: roomObj }),
    updateMainReservationBtn: bool =>
      dispatch({
        type: `SHOW_MAIN_RESERVATION_BUTTON`,
        payload: bool,
      }),
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
    updateRoomUrl: url =>
      dispatch({
        type: `UPDATE_ROOM_URL`,
        payload: url,
      }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomComponent)
