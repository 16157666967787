import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Image } from "../../components/gatsby-images/image"
import { connect } from "react-redux"

const Bathroom = ({
  mainImage,
  firstImage,
  secondImage,
  thirdImage,
  scrolltriggerReady,
  language,
}) => {
  let mainImg = useRef(null)
  let firstImg = useRef(null)
  let secondImg = useRef(null)
  let thirdImg = useRef(null)

  //tween
  let mainImageTween = useRef()
  let timelineImagesTween = useRef()

  useEffect(() => {
    if (scrolltriggerReady) {
      ScrollTrigger.getById(`mainImage-room-${firstImage}`) &&
        ScrollTrigger.getById(`mainImage-room-${firstImage}`).kill()
      mainImageTween.current = gsap.to(mainImg, {
        scrollTrigger: {
          id: `mainImage-room-${firstImage}-${language}`,
          trigger: mainImg,
          scrub: true,
          start: "top 50%",
          end: "bottom 50%",
        },
        scale: 1,
      })

      let tl = gsap.timeline()
      timelineImagesTween.current = tl
        .fromTo(
          secondImg,
          { x: -100 },
          {
            scrollTrigger: {
              id: `bathroom-room-${secondImage}`,
              trigger: firstImg,
              start: "bottom 85%",
              end: "+=200",
              scrub: true,
            },
            x: 0,
          }
        )
        .fromTo(
          thirdImg,
          { x: 100 },
          {
            scrollTrigger: {
              id: `bathroom-room-${thirdImg}`,
              trigger: firstImg,
              start: "bottom 85%",
              end: "+=200",
              scrub: true,
            },
            x: 0,
          }
        )
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      mainImageTween.current && mainImageTween.current.kill()
      timelineImagesTween.current && timelineImagesTween.current.kill()
    }
  }, [])
  return (
    <div className="bathroom-container">
      <div className="main-image-container">
        <div className="main-image" ref={el => (mainImg = el)}>
          <Image src={mainImage} />
        </div>
      </div>
      <div className="bathrooms-photos">
        <div className="row">
          <div className="offset-2">
            <div className="first-image" ref={el => (firstImg = el)}>
              <Image src={firstImage} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="offset-1 second-image" ref={el => (secondImg = el)}>
            <Image src={secondImage} />
          </div>
          <div className="third-image" ref={el => (thirdImg = el)}>
            <Image src={thirdImage} />
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ scrolltriggerReady, language }) => {
  return { scrolltriggerReady, language }
}

export default connect(mapStateToProps, null)(Bathroom)
