import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { Image } from "../../components/gatsby-images/image"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const MirrorComponent = ({ mainImage, mirrorImage, scrolltriggerReady }) => {
  let mirrorImg = useRef(null)
  let mainImg = useRef(null)

  //tween
  let mirrorTween = useRef(null)

  useEffect(() => {
    let heightMirror = mainImg.getBoundingClientRect().height / 2
    if (scrolltriggerReady) {
      ScrollTrigger.getById(`first-mirror-${mirrorImage}-room`) &&
        ScrollTrigger.getById(`first-mirror-${mirrorImage}-room`).kill()
      mirrorTween.current = gsap.to(mirrorImg, {
        scrollTrigger: {
          id: `first-mirror-${mirrorImage}-room`,
          trigger: mainImg,
          start: "top 50%",
          end: "bottom 80%",
          scrub: true,
        },
        scale: 1,
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      mirrorTween.current && mirrorTween.current.kill()
    }
  }, [])
  return (
    <div className="row mirror">
      <div className="main-image col-12">
        <div className="main-image" ref={el => (mainImg = el)}>
          <Image src={mainImage} />
        </div>
        <div className="mirror-image-container">
          <div className="mirror-image" ref={el => (mirrorImg = el)}>
            <Image src={mirrorImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(MirrorComponent)
