import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { Image } from "../../components/gatsby-images/image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"

const Banner = ({ roomObj, scrolltriggerReady }) => {
  let roomTitle = roomObj.title
  let bannerObj = roomObj
  let image = useRef()

  //tween
  let imageTween = useRef()

  useEffect(() => {
    if (scrolltriggerReady) {
      ScrollTrigger.getById(`banner-room-${bannerObj.bannerPhoto}`) &&
        ScrollTrigger.getById(`banner-room-${bannerObj.bannerPhoto}`).kill()
      gsap.to(image, {
        scrollTrigger: {
          id: `banner-room-${bannerObj.bannerPhoto}`,
          start: "top top",
          end: "30%",
          scrub: true,
          delay: 0.1,
        },
        y: -150,
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      imageTween.current && imageTween.current.kill()
    }
  }, [])
  return (
    <div className="room-component">
      <div className="banner-container">
        <div className="row">
          <div className="col-sm-6 offset-sm-3 image-container">
            <div className="banner-image" ref={el => (image = el)}>
              <Image src={bannerObj.bannerPhoto} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 offset-sm-2  text-center">
            <div className="text-container ">
              <div className="title">{roomTitle}</div>
              {bannerObj.titles.map(title => {
                return (
                  <div key={title} className="main-titles">
                    {title}
                  </div>
                )
              })}
              <div className="hand-typo">
                <Trans>Chambre title room</Trans>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 offset-sm-3 col-10 offset-1 text-center">
            <div className="description">{bannerObj.description}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(Banner)
