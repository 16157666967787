import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { Image } from "../../components/gatsby-images/image"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const PhotoSlider = ({ sliderImg, bigImg, scrolltriggerReady }) => {
  let slider = useRef(null)
  let bigImage = useRef(null)

  //tween
  let photoSliderTween = useRef(null)

  useEffect(() => {
    const bigImageHeight = bigImage.getBoundingClientRect().height
    const slideImageHeight = slider.getBoundingClientRect().height
    const movingY = bigImageHeight - slideImageHeight
    if (scrolltriggerReady) {
      ScrollTrigger.getById(`first-slider-${bigImg}`) &&
        ScrollTrigger.getById(`first-slider-${bigImg}`).kill()
      photoSliderTween.current = gsap.to(slider, {
        scrollTrigger: {
          id: `first-slider-${bigImg}`,
          trigger: bigImage,
          start: "top 50%",
          end: "bottom 50%",
          scrub: true,
          ease: "linear",
        },
        y: movingY,
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      photoSliderTween.current && photoSliderTween.current.kill()
    }
  }, [])

  return (
    <div className="photo-slider-container">
      <div className="row">
        <div className="col-3 offset-1 small-slider" ref={el => (slider = el)}>
          <Image src={sliderImg} />
        </div>
        <div className="col-8 big-image" ref={el => (bigImage = el)}>
          <Image src={bigImg} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(PhotoSlider)
